import Section from "../../section/Section";

const SingleBlogHero = ({ data }) => (
  <Section className="my-6 my-md-10" smallContainer={true}>
    <img
      src={data.feature_image.url}
      width={data.feature_image.gatsbyImageData.width}
      height={data.feature_image.gatsbyImageData.height}
      style={{ width: "100%", height: "auto" }}
      alt=""
    />
  </Section>
);

export default SingleBlogHero;
