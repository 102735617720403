import useResponsive from "../../../../../src/_hooks/useResponsive";
import { getOrElse } from "../../../../../src/_helpers/_utilities";

const SingleBlogDetails = ({ author, category, time_to_read }) => {
  const isMobile = useResponsive();

  const categoryName = category.document.data.category_name.text;
  const authorName = getOrElse(() => author.document.data.name.text, null);
  const authorImage = getOrElse(
    () => author.document.data.profile_photo.url,
    null
  );

  return (
    <div className="mt-4 mt-md-6 mb-6 mb-md-10 d-flex flex-column flex-md-row align-items-start align-items-md-center">
      {authorName && (
        <span className="d-flex align-items-center mb-4 mb-md-0">
          <img
            src={authorImage}
            width={isMobile ? 32 : 48}
            height={isMobile ? 32 : 48}
            className="rounded-circle"
          />
          <span className="d-block ml-2 font-weight-bold">by {authorName}</span>
        </span>
      )}
      <div>
        {authorName && (
          <span
            className="d-none d-md-inline-block mx-4 bg-violet-mid rounded-circle"
            style={{ height: 6, width: 6 }}
          />
        )}
        <a
          className="link"
          href={`/blog/${categoryName.toLowerCase().replace(/\s/g, "-")}/`}
        >
          {categoryName}
        </a>
        <span
          className="d-inline-block mx-4 bg-violet-mid rounded-circle"
          style={{ height: 6, width: 6 }}
        />
        <span className="text-gray-dark small-text">
          {time_to_read} min read
        </span>
      </div>
    </div>
  );
};

export default SingleBlogDetails;
