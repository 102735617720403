import * as React from "react";
import { graphql } from "gatsby";

import Document from "../../components/v2/Document";

import SingleBlogHero from "../../components/_pages/single-blog/SingleBlogHero";
import Section from "../../components/section/Section";
import Title from "../../components/Title";
import SingleBlogContent from "../../components/_pages/single-blog/SingleBlogContent";
import { Helmet } from "react-helmet";
import { truncateText } from "../../../../src/_helpers/_utilities";

import SparkSection from "../../components/_pages/v2/home/SparkSection";
import ButtonWithArrow from "../../components/v2/ButtonWithArrow";

const BlogPostDocument = ({ article }) => (
	<article className="py-5">
		<SingleBlogHero {...article} />
		<Section smallContainer={true} className="pb-8">
			<Title className="text-left">{article.data.title.text}</Title>
			<SingleBlogContent {...article.data} heroImageSrc={article.data.feature_image.url} />
		</Section>
		<div className="d-flex justify-content-center align-items-center py-5">
			<ButtonWithArrow href="/blog" text="Read more" />
		</div>
		<SparkSection />
	</article>
);

const BlogPost = ({ data }) => {
	if (!data) return null;
	const doc = data.prismicBlogArticle;

	return (
		<Document
			title={`${
				doc.data.meta_title && doc.data.meta_title.text ? doc.data.meta_title.text : "Blog - " + doc.data.title.text
			}`}
			className={`single-blog single-blog-${doc.uid}`}
			ogData={{
				description: truncateText(
					doc.data.meta_description && doc.data.meta_description.text
						? doc.data.meta_description.text
						: doc.data.content.text,
					200
				).replace(/\n/g, ""),
				image: doc.data.feature_image.url,
			}}
		>
			<BlogPostDocument article={doc} />
			<Helmet>
				<script type="application/ld+json">
					{`
				  {
					  "@context": "https://schema.org",
					  "@type": "BlogPosting",
					  "mainEntityOfPage": {
					    "@type": "WebPage",
					    "@id": "${process.env.GATSBY_SITE_URL + "/blog/" + doc.uid}"
					  },
					  "headline": "${doc.data.title.text}",
					  "description": "${truncateText(doc.data.content.text, 200).replace(/\n/g, "")}",
					  "image": "${doc.data.feature_image.url}",
					  "author": {
					    "@type": "Organization",
					    "name": "Stay Nimble",
					    "url": "https://staynimble.co.uk"
					  }
					}
			`}
				</script>
			</Helmet>
		</Document>
	);
};

export const query = graphql`
	query BlogArticleQuery($id: String) {
		prismicBlogArticle(id: { eq: $id }) {
			uid
			data {
				title {
					text
				}
				content {
					text
					html
				}
				feature_image {
					gatsbyImageData
					url
				}
				category {
					document {
						... on PrismicBlogCategory {
							id
							data {
								category_name {
									text
								}
							}
						}
					}
				}
				author {
					document {
						... on PrismicAuthor {
							id
							data {
								name {
									text
								}
								profile_photo {
									dimensions {
										height
										width
									}
									url
								}
								role {
									text
								}
							}
						}
					}
				}
				pushed_date
				sidebar_content {
					html
				}
				sidebar_heading {
					text
				}
				meta_title {
					text
				}
				meta_description {
					text
				}
				time_to_read
			}
		}
	}
`;

export default BlogPost;
