import { useMemo } from "react";
import { isEmpty } from "lodash";
import useResponsive from "../../../../../src/_hooks/useResponsive";
import SingleBlogDetails from "./SingleBlogDetails";
import SingleBlogSide from "./SingleBlogSide";

const SingleBlogContent = ({
  content,
  sidebar_heading,
  sidebar_content,
  heroImageSrc,
  ...props
}) => {
  const isMobile = useResponsive();

  const { html } = content;
  const formattedHTML = useMemo(() => {
    // Like jQuery for node
    const cheerio = require("cheerio");
    const $ = cheerio.load(html);
    $(".block-img").each((_, block) => {
      $(block).addClass("text-center").find("img").addClass("img-responsive");
    });
    $("p").each((_, p) => {
      p = $(p);
      if (isEmpty(p.html()) || p.html() === "<br>") {
        p.remove();
      }
    });
    $("h3").each((_, h) => {
      h = $(h);
      h.find("br").each((_, br) => {
        $(br).remove();
      });
    });
    $("a").each((_, a) => {
      $(a).addClass("link").css("font-size", "15px");
    });
    //
    const firstChild = $("body > *:first-child");
    if (firstChild.hasClass("block-img")) {
      const image = firstChild.find("img");
      const imageSrc = image[0].attribs.src;
      const imageSrcPure = imageSrc.replace(/([^?]+)(\?.*)/, "$1");
      const heroImageSrcPure = heroImageSrc.replace(/([^?]+)(\?.*)/, "$1");
      if (imageSrcPure === heroImageSrcPure) {
        firstChild.remove();
      }
    }

    return $.html();
  }, [html]);

  return (
    <div className="d-flex flex-column d-md-block">
      {/*<SingleBlogSide {...{ sidebar_heading, sidebar_content }} />*/}
      <SingleBlogDetails {...props} />
      <div dangerouslySetInnerHTML={{ __html: formattedHTML }} />
    </div>
  );
};

export default SingleBlogContent;
